import { Grid } from "@mui/material";
import gsap from "gsap";
import { TweenMax } from "gsap/gsap-core";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import GoToTop from "../../GoToTop.js";
import styles from "../../styles/Network.module.css";import * as data from "../../../data.js";

export default function VirtualAgentsSpeed({ currentLanguage }) {
  const dataModules = require.context("../../../data", false, /\.js$/);
  const languageData = {};

  dataModules.keys().forEach((key) => {
    const langCode = key.replace(/^\.\/(.+)\.js$/, "$1");
    languageData[langCode] = dataModules(key);
  });

  const [datas, setDatas] = useState(languageData[currentLanguage]);
  const [filteredData, setFilteredData] = useState(null);

  const params = useParams().id;
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  const revealsSecondRef = useRef([]);
  revealsFirstRef.current = [];
  revealsSecondRef.current = [];

  useEffect(() => {
    setDatas(languageData[currentLanguage]);
  }, [currentLanguage]);

  useEffect(() => {
    if (datas) {
      const newState = datas.VirtualAgentsimgs.filter((item) => item.id == params)[0];
      setFilteredData(newState);
    }
  }, [datas, params]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );

    revealsFirstRef.current.forEach((el, index) => {
      TweenMax.fromTo(
        el,
        1.5,
        {
          width: "80%",
          marginLeft: "10%",
          marginRight: "10%",
          delay: 0,

        },
        {
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          delay: 0,
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top bottom",
            toggleActions: "play none none ",

          },
        }
      );
    });

    revealsSecondRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };

  const addToSecondRef = (el) => {
    if (el && !revealsSecondRef.current.includes(el)) {
      revealsSecondRef.current.push(el);
    }
  };


  return (
    <>
      <GoToTop />
      <div className={styles?.enterprise}>
        <div >
          <div className={styles["enterprise-title"]} ref={detailRef}>
            <p>{filteredData?.date}</p>
            <h2 className={styles["enterprise-H"]}>{filteredData?.title}
            </h2>
          </div>
        </div>
        <div>
          <Grid container>
            <Grid item>
              {filteredData?.drilldown?.map((item,i) => (
                
                <div key={i} className={styles["enterprise-items"]}>
                  <div ref={addToFirstRef} >
                    <img
                      src={process.env.PUBLIC_URL + item.img1}
                      alt=''
                      className={styles["img-rect"]}></img>
                  </div>
                  <div ref={detailRef}>

                    <p className={styles.para1}>
                      {item?.desc}
                    </p>

                    <h6 className={styles["Head-6"]}>
                      {item?.subTitle1}
                      </h6>
                    <p className={styles.para2}>
                      {item?.desc1}
                    </p>
                    {item?.point?.desc1?.map((descitems,i) => (
                        <p key={i} style={{ marginLeft: 0 }} className={styles.para2}>{descitems}</p>
                      ))}

                    {item.subtitle2 &&  (<h6 className={styles["Head-6"]}>
                      {item.subtitle2}</h6>) }

                    {item.desc2 && (<p className={styles.para2}>
                      {item?.desc2}
                    </p>)}
                    {item.point?.desc2?.map((descitems, i) => (
                      <p key={i} style={{ marginLeft: 0 }} className={styles.para2}>{descitems}</p>
                    ))}




                  </div>

                  { item.img2 && item.img2.length > 0 &&   <img
                    src={process.env.PUBLIC_URL + item.img2}
                    alt=''
                    className={styles["img-enterprise"]} ref={addToSecondRef} /> }
                  <div ref={detailRef}>
                          <h6 className={styles["Head-6"]}>
                      {item?.subtitle3_half}</h6>
                    <div>
                    {item?.point?.desc3_half?.map((descr,i) => (
                          <p key={i} style={{ marginLeft: 0 }} className={styles.para2}>{descr}</p>
                      ))}
                    </div>

                    <h6 className={styles["Head-6"]}>
                      {item?.subtitle3}</h6>
                    <div>
                      {item?.point?.desc3?.map((descr, i) => (
                        <p key={i} style={{ marginLeft: 0 }} className={styles.para2}>{descr}</p>
                      ))}
                    </div>
                    <h6 className={styles["Head-6"]}>
                      {item?.subtitle5}</h6>
                    <div>
                      {item?.point?.desc5?.map((descrr, i) => (
                        <p key={i} style={{ marginLeft: 0 }} className={styles.para2}>{descrr}</p>
                      ))}
                    </div>

                    <p className={styles.para2}>
                  {item?.desc3}
                </p>



<div>


</div>


                  </div>
                  
                </div>

              ))}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
