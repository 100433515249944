import React, { useState, useEffect, useRef } from "react";
import { Grid, CardMedia, Box, Typography } from "@mui/material";
import styles from "../../styles/AiServices.module.css";
import { FormattedMessage } from "react-intl";
import { useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import GoToTop from "../../GoToTop";

export default function AiServices() {
  const intl = useIntl();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const lang = params.get("lang");
  const [showOpenPosition, setShowOpenPosition] = useState(false);
  const history = useHistory();
  const { state } = useLocation();
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  const revealsSecondRef = useRef([]);
  revealsFirstRef.current = [];
  revealsSecondRef.current = [];

  const box1 = useRef(null);
  const box2 = useRef(null);
  const box3 = useRef(null);
  const box4 = useRef(null);
  const box5 = useRef(null);
  const box6 = useRef(null);

  useEffect(() => {
    const boxes1 = [box1.current, box2.current, box3.current];

    const boxes2 = [box4.current, box5.current, box6.current];

    boxes1.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        stagger: 0.2,
        y: 0,
        scale: 1,
        duration: 1.5,

        delay: index * 0.2,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes2.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,

        delay: index * 0.3,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });
  }, []);

  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    revealsFirstRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });

    revealsSecondRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };

  const addToSecondRef = (el) => {
    if (el && !revealsSecondRef.current.includes(el)) {
      revealsSecondRef.current.push(el);
    }
  };


  return (

    <Grid container justifyContent={"center"}>
      <Grid item xs={12} className={styles.title_conatiner}>
        <Typography variant="h3" mb={"1.5rem"} className={styles.title}>
          <span className={styles.line1} maxWidth={"55rem"}>We Decode The Complex Human Problems</span>
          <br />
          <span >Through The Transformative Power Of</span>
          <br />
          <span>Artificial Intelligence</span>
        </Typography>

        <Box maxWidth={"43rem"}>
          <Typography variant="body" className={styles.subtitle}>
            Your AI and Machine Learning Partner
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={9} className={styles.banner_video_container1}>
        <video
          style={{ maxWidth: "100%" }}
          muted
          autoPlay
          loop
          playsInline
          preload="auto"
          loading="lazy"
        >
          <source
            src={process.env.PUBLIC_URL + "/assets/videos/videoCard.mp4"}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Grid>
      <Grid item xs={12} className={styles.banner_container}>
        <Grid item xs={12} >
          <Box
            component="img"
            className={styles.banner_aiservices}
            alt="Banner"
            src={
              process.env.PUBLIC_URL +
              "/assets/images/aiservices/bannerimg.svg"
            }
          />
        </Grid>
      </Grid>
      <div className={styles.aiservices_containers}>
        <GoToTop />
        <div>
          <div className={`${styles["aiservices-content"]} ${styles["bot-pad"]}`}>
            <Grid container spacing={2} id='' ref={addToFirstRef} className={styles["aiservices-images"]}>
              <Grid
                ref={box1}
                item
                xl={12}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={styles["aiservices-content1"]}>
                <div className={styles["img-hover-zoom"]}>
                  <div style={{ height: "600px", width: "600px" }} className={styles["img-hover-zoom"]}>
                    <Link
                      New
                      Message
                      to={{
                        pathname: "/aiconsulting",
                        search: `?lang=${lang}`,
                      }}>
                      <img
                        className={styles["img-res"]}
                        id='imghover'
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/aiservices/aiconsulting.svg"
                        }
                        alt='AIConsulting'
                        style={{ width: "100%" }}
                      />
                    </Link>
                  </div>
                </div>

                <div>
                  <h3 className={styles["img-sub-h"]}>AI Consulting & Custom AI Development</h3>
                </div>
              </Grid>
              <Grid
                ref={box2}
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={styles["aiservices-content1"]}>
                <div className={styles["img-hover-zoom"]}>
                  <div style={{ height: "600px", width: "600px" }} className={styles["img-hover-zoom"]}>
                    <Link
                      New
                      Message
                      to={{
                        pathname: "/intelligentautomation",
                        search: `?lang=${lang}`,
                      }}
                    >
                      <img
                        className={styles["img-res"]}
                        id='imghover'
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/aiservices/customaidevelop.svg"
                        }
                        alt='CustomaiDevelopment'
                        style={{ width: "100%" }}
                      />
                    </Link>
                  </div>
                </div>

                <div>
                  <h3 className={styles["img-sub-h"]}>
                  Intelligent Automation
                  </h3>
                </div>
              </Grid>
              <Grid
                ref={box5}
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={styles["aiservices-content1"]}>
                <div className={styles["img-hover-zoom"]}>
                  <div style={{ height: "600px", width: "600px" }} className={styles["img-hover-zoom"]}>
                    <Link
                      New
                      Message
                      to={{
                        pathname: '/dataintelligenceanalytics',
                        search: `?lang=${lang}`,
                      }}
                    >
                      <img
                        className={styles["img-res"]}
                        id='imghover'
                        src='/assets/images/aiservices/dataintelligence.svg'
                        style={{ width: "100%" }}
                        alt="DataIntelligenceAnalytics"
                      />
                    </Link>
                  </div>
                </div>

                <div>
                  <h3 className={styles["img-sub-h"]}>
                  Data Intelligence and Analytics
                  </h3>
                </div>
              </Grid>
              <Grid
                ref={box3}
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={styles["aiservices-content1"]}>
                <div className={styles["img-hover-zoom"]}>
                  <div style={{ height: "600px", width: "600px" }} className={styles["img-hover-zoom"]}>
                    <Link
                      New
                      Message
                      to={{
                        pathname: '/virtualagents',
                        search: `?lang=${lang}`,
                      }}
                    >
                      <img
                        className={styles["img-res"]}
                        id='imghover'
                        src='/assets/images/aiservices/intelligentautomation.svg'
                        alt='IntelligentAutomation'
                        style={{ width: "100%" }}
                      />
                    </Link>
                  </div>
                </div>
                <div>
                  <h3 className={styles["img-sub-h"]}>
                  Virtual Agents
                  </h3>
                </div>
              </Grid>
              <Grid
                ref={box4}
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={styles["aiservices-content1"]}>
                <div
                  className={styles["img-hover-zoom"]}>
                  <div style={{ height: "600px", width: "600px" }} className={styles["img-hover-zoom"]}>
                    <Link
                      New
                      Message
                      to={{
                        pathname: '/aiintegration',
                        search: `?lang=${lang}`,
                      }}
                    >
                      <img
                        className={styles["img-res"]}
                        id='imghover'
                        src='/assets/images/aiservices/aiIntegration.svg'
                        alt='AiIntegration'
                        style={{ width: "100%" }}
                      />
                    </Link>
                  </div>
                </div>
                <div>
                  <h3 className={styles["img-sub-h"]}>
                  AI Integration
                  </h3>
                </div>
              </Grid>

              <Grid
                ref={box6}
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={styles["aiservices-content1"]}>
                <div className={styles["img-hover-zoom"]}>
                  <div style={{ height: "600px", width: "600px" }} className={styles["img-hover-zoom"]}>
                    <Link
                      New
                      Message
                      to={{
                        pathname: "/vectorsearch",
                        search: `?lang=${lang}`,
                      }}
                    >
                      <img
                        className={styles["img-res"]}
                        id='imghover'
                        src='/assets/images/aiservices/vectorsearch.svg'
                        style={{ width: "100%" }}
                        alt="vectorsearch"
                      />
                    </Link>
                  </div>
                </div>

                <div>
                  <h3 className={styles["img-sub-h"]}>
                  Vector Search & Retrieval augmented generation (RAG)
                  </h3>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Grid>
  );
};

