import React, { useRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
// import styles from "../../styles/AiConsulting.module.css";
import styles from "../../styles/VectorSearchCaseStudies.module.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as data from "../../../data.js";
import GoToTop from "../../GoToTop";
import { FormattedMessage } from "react-intl";
import { useIntl } from 'react-intl';
import { Link, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import Search from "../CustomSearch/Search";
export default function VectorSearch({ currentLanguage }) {
  const dataModules = require.context("../../../data", false, /\.js$/);
  console.log(dataModules, "dataModules");
  const languageData = {};

  dataModules.keys().forEach((key) => {
    const langCode = key.replace(/^\.\/(.+)\.js$/, "$1");
    languageData[langCode] = dataModules(key);
  });
  const [data, setData] = useState(languageData[currentLanguage]);
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  const revealsSecondRef = useRef([]);
  revealsFirstRef.current = [];
  revealsSecondRef.current = [];

  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    revealsFirstRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });
  }, []);
  const lindinClickHAndler = (e) => {
    window.open(`https://in.linkedin.com/company/datatemplate`);
  };
  const instaClickHAndler = (e) => {
    window.open(`https://www.instagram.com/datatemplate/`);
  };

  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: 'casestudies_search' });

  gsap.registerPlugin(ScrollTrigger);
  const searchParam = window.location.search;
  const params = new URLSearchParams(searchParam);
  const lang = params.get("lang");

  const [datas, setDatas] = useState([]);
  const [industries, setIndustries] = useState('');
  const [services, setSerrvices] = useState('');
  const [search, setSearch] = useState('');
  const [filterData, setFilterData] = useState([]);

  revealsFirstRef.current = [];
  revealsSecondRef.current = [];

  const history = useHistory()
  const route = useRouteMatch()
  const { industry } = useParams()



  useEffect(() => {
    let filterCaseStudies = datas && datas?.filter((caseStudies) => {
      return (
        caseStudies.title
          ?.toLowerCase()
          .includes(search.toLowerCase())

      )

    })


    if (industries.length > 0 && search.length >= 0) {
      filterCaseStudies = filterCaseStudies?.filter((data) => {
        return (
          (data?.industry.includes(industries)) && data.title.toLowerCase().includes(search.toLowerCase()) ||
          (data?.service.includes(industries)) && data.title.toLowerCase().includes(search.toLowerCase())
        )
      })
    }
    const sortedData = filterCaseStudies?.sort((a, b) => a.title.localeCompare(b.title))
    filterCaseStudies && setFilterData(sortedData)
  }, [datas, search, industries, route.path])

  useEffect(() => {
    if (data && data?.vectorsearchimgs.length > 0) {
      setDatas(data?.vectorsearchimgs)
    }
    if (industry && industry.length > 0) {
      setIndustries(industry)
    }
    if (industry === undefined) {
      setIndustries('')
    }

  }, [data, industry, route.path])


  const caseStudiesChangeHandler = (e) => {
    setSearch(e.target.value)
  }
  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    revealsFirstRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });

    revealsSecondRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };

  const addToSecondRef = (el) => {
    if (el && !revealsSecondRef.current.includes(el)) {
      revealsSecondRef.current.push(el);
    }
  };

  const clickHandler = () => {
    history.push('network')
  }

  const casecloseButtonHandler = () => {
    setSearch('')
  }



  return (
    <div className={styles.vectorSearch_container}>
      <GoToTop />
      <div className={styles.vectorSearch}>
        <div ref={detailRef} className={styles.contRes}>
          <div className={styles["vectorSearch-title"]}>
            <h2 className={styles["vectorSearchHeading-1"]}>
            Vector Search & Retrieval augmented generation (RAG)
            </h2>
          </div>
          <div className={styles["vectorSearch-content"]}>
            <p className={styles["vectorSearch-contentp"]}>
            Vector Search and Retrieval Augmented Generation (RAG) are advanced techniques used in natural language processing (NLP) and AI to enhance search accuracy, improve knowledge retrieval, and generate highly relevant responses in AI applications. They combine vector embeddings, which represent text or data as multi-dimensional vectors, with generative models to deliver contextually accurate and data-backed results.
            </p>{" "}
          </div>
        </div>
        <div style={styles.casestudiesContainer}>
          <div className={styles.aboutUs} ref={detailRef}>


            <div className={styles["input-icon my-3 ml-auto"]}>
              <Search
                id='search'
                type='search'
                className={styles.searchCase}
                placeholder={placeholder}
                tabIndex='1'
                aria-label='Search'
                onChange={caseStudiesChangeHandler}
                value={search}
                onClick={casecloseButtonHandler}
                closeBtn={true}
              />

            </div>
          </div>
          <div className={styles["casestudy-div"]}>
            <Grid id='case-part' className={styles['case-part']} container spacing={2}>
              {filterData && filterData?.map((item, index) => {
                return (
                  <Grid
                    key={index}
                    className={styles["casestudies-img"]}
                    ref={addToSecondRef}
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}>
                    <div className=''>
                      <Link
                        New
                        Message
                        to={{
                          pathname: `/vectorsearchcasestudies/vectorsearchcaseInfo/${item.id}`,
                          search: `?lang=${lang}`,
                        }}
                      >
                        <div className={styles["img-hover-zoom"]}>
                          <img className={styles["case-img"]} id="imghover" src={item.img} alt={item.title} loading="lazy" />
                        </div>
                      </Link>
                      <p className={styles.casetitle}>{item.date}</p>
                      <h4 className={styles.casesubtitle1}>{item.title}</h4>
                    </div>
                  </Grid>
                );
              })}
              {filterData?.length === 0 && <p className={styles['no-case-studies-found']}>No CaseStudies Found</p>}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
