import React, { useRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import styles from "../../styles/About.module.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as data from "../../../data";
import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop";
import { FormattedMessage } from "react-intl";

export default function AboutUs({ currentLanguage }) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const lang = params.get("lang");
  const dataModules = require.context("../../../data", false, /\.js$/);
  console.log(dataModules, "dataModules");
  const languageData = {};

  dataModules.keys().forEach((key) => {
    const langCode = key.replace(/^\.\/(.+)\.js$/, "$1");
    languageData[langCode] = dataModules(key);
  });
  const [data, setData] = useState(languageData[currentLanguage]);
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  const revealsSecondRef = useRef([]);
  revealsFirstRef.current = [];
  revealsSecondRef.current = [];

  const box1 = useRef(null);
  const box2 = useRef(null);
  const box3 = useRef(null);
  const box4 = useRef(null);
  const box5 = useRef(null);
  const box6 = useRef(null);
  const box7 = useRef(null);
  const box8 = useRef(null);
  const box9 = useRef(null);
  const box10 = useRef(null);
  const box11 = useRef(null);
  const box12 = useRef(null);
  const box13 = useRef(null);
  const box14 = useRef(null);
  const box15 = useRef(null);
  const box16 = useRef(null);
  const box17 = useRef(null);
  const box18 = useRef(null);
  const box19 = useRef(null);
  const box20 = useRef(null);
  const box21 = useRef(null);
  const box22 = useRef(null);
  const box23 = useRef(null);
  const box24 = useRef(null);
  const box25 = useRef(null);
  const box26 = useRef(null);

  useEffect(() => {
    const boxes1 = [box1.current, box2.current, box3.current];

    const boxes2 = [box4.current, box5.current, box6.current];

    const boxes3 = [box7.current, box8.current, box9.current];

    const boxes4 = [box10.current, box11.current, box12.current];

    const boxes5 = [box13.current, box14.current, box15.current];

    const boxes6 = [box16.current, box17.current, box18.current];

    const boxes7 = [box19.current, box20.current, box21.current];

    const boxes8 = [
      box22.current,
      box23.current,
      box24.current,
      box25.current,
      box26.current,
    ];

    boxes1.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        stagger: 0.2,
        y: 0,
        scale: 1,
        duration: 1.5,

        delay: index * 0.2,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes2.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,

        delay: index * 0.3,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes3.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,

        delay: index * 0.3,
        duration: 1.5,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes4.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.3,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes5.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        stagger: 0.2,
        y: 0,
        scale: 1,
        duration: 1.5,

        delay: index * 0.2,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes6.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        stagger: 0.2,
        y: 0,
        scale: 1,
        duration: 1.5,

        delay: index * 0.2,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes7.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        stagger: 0.2,
        y: 0,
        scale: 1,
        duration: 1.5,

        delay: index * 0.2,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes8.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        stagger: 0.2,
        y: 0,
        scale: 1,
        duration: 1.5,

        delay: index * 0.2,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });
  }, []);

  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    revealsFirstRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };

  const addToSecondRef = (el) => {
    if (el && !revealsSecondRef.current.includes(el)) {
      revealsSecondRef.current.push(el);
    }
  };

  return (
    <>
      <GoToTop />
      <div className={styles.aboutUs}>
        <div className={styles["aboutus-container"]} ref={detailRef}>
          <div className={styles["aboutUs-title"]}>
            <h1 className={styles.aboutheading1}>DATA TEMPLATE</h1>
            <h1 className={styles.aboutheading2}>
              <FormattedMessage id="title_aboutus" />
            </h1>
          </div>
          <div className={styles["aboutUs-content"]}>
            <p className={styles["aboutUs-contentp"]}>
              <FormattedMessage id="paragraph1_aboutus" />
            </p>
            <p className={styles["aboutUs-contentp1"]}>
              <FormattedMessage id="paragraph2_aboutus" />
            </p>
            <p className={styles["aboutUs-contentp1"]}>
              {" "}
              <FormattedMessage id="paragraph3_aboutus" />
            </p>
          </div>
        </div>
        <div className={styles["aboutUs-img"]}>
          <Grid
            id="about-part"
            className={styles["about-part"]}
            container
            spacing={2}
          >
            {data?.about?.map((item, index) => {
              return (
                <Grid
                  className={styles.aboutimages}
                  key={index}
                  ref={addToFirstRef}
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <div className={styles["about-img-space"]} id={item.id}>
                    <div className={styles["img-hover-zoom"]}>
                      <img
                        className={styles["about-img"]}
                        id="imghover"
                        src={process.env.PUBLIC_URL + item.img}
                        alt="About"
                        loading="lazy"
                      />
                    </div>
                    <p className={styles.abouttitle}>{item.title}</p>
                    <h4 className={styles.aboutsubtitle}>{item.subTitle}</h4>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>

        <div className={styles["aboutUs2-img"]}>
          <Grid
            id="about-part"
            className={`${styles["about-part"]} ${styles["about-part2"]}`}
            container
            spacing={2}
          >
            <Grid
              ref={box2}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/aboutus/nirmal.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="nirmal_title_aboutus" />{" "}
                </p>
                <h4 className={styles.aboutsubtitle2}>Nirmal</h4>
              </div>
            </Grid>
            <Grid
              ref={box3}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/aboutus/nitish.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="nitish_title_aboutus" />
                </p>
                <h4 className={styles.aboutsubtitle2}>Nitish Biswas</h4>
              </div>
            </Grid>
            <Grid
              ref={box4}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/aboutus/sanjeetnew.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="sanjeet_aboutus" />
                </p>
                <h4 className={styles.aboutsubtitle2}>Sanjeet Sinha</h4>
              </div>
            </Grid>

            <Grid
              ref={box5}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/aboutus/ramanathan.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="ramanathan_aboutus" />{" "}
                </p>
                <h4 className={styles.aboutsubtitle2}>Ramanathan</h4>
              </div>
            </Grid>
            <Grid
              ref={box6}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/aboutus/venkateshwari.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="eswari_aboutus" />{" "}
                </p>
                <h4 className={styles.aboutsubtitle2}>Eswari Ganesh</h4>
              </div>
            </Grid>
            <Grid
              ref={box7}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/aboutus/chandelle.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="chandelle_aboutus" />{" "}
                </p>
                <h4 className={styles.aboutsubtitle2}>
                  Chandelle Mutezintare Brown
                </h4>
              </div>
            </Grid>
            <Grid
              ref={box8}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}>
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id='imghover'
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/aboutus/sergeysidorov.svg"
                  }
                  alt='About'
                  loading='lazy'
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="sergeysidorov_head" />
                </p>
                <h4 className={styles.aboutsubtitle2}>Sergey Sidorov</h4>
              </div>
            </Grid>
            <Grid
              ref={box8}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}>
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id='imghover'
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/aboutus/sureshmethale.svg"
                  }
                  alt='About'
                  loading='lazy'
                />
              </div>
              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="suresh_methale_p_aboutus" />
                </p>
                <h4 className={styles.aboutsubtitle2}>KP Viswanathan</h4>
              </div>
            </Grid>
            <Grid
              ref={box10}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/aboutus/suresh.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="suresh_aboutus" />
                </p>
                <h4 className={styles.aboutsubtitle2}>
                  Suresh Methale Purayil
                </h4>
              </div>
            </Grid>

            <Grid
              ref={box11}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/aboutus/ajay.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="dr_ajay_aboutus" />{" "}
                </p>
                <h4 className={styles.aboutsubtitle2}>
                  Dr. Ajay Vamadevan Sarala
                </h4>
              </div>
            </Grid>
            <Grid
              ref={box12}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/aboutus/aldenora.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="aldenora_aboutus" />
                </p>
                <h4 className={styles.aboutsubtitle2}>Aldenora Neta</h4>
              </div>
            </Grid>
            <Grid
              ref={box14}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/aboutus/jesusupdate.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="jesus_aboutus" />{" "}
                </p>
                <h4 className={styles.aboutsubtitle2}>Jesus Rodriguez</h4>
              </div>
            </Grid>
            <Grid
              ref={box9}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={process.env.PUBLIC_URL + "/assets/images/aboutus/sl.svg"}
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="subhalakshmi_aboutus" />
                </p>
                <h4 className={styles.aboutsubtitle2}>
                  Subhalakshmi Raman Iyer
                </h4>
              </div>
            </Grid>
            <Grid
              ref={box14}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/aboutus/neeraj.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="neeraj_aboutus" />
                </p>
                <h4 className={styles.aboutsubtitle2}>Neeraj Fernandes</h4>
              </div>
            </Grid>
            <Grid
              ref={box16}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/aboutus/shyamupdate.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="shyam_aboutus" />
                </p>
                <h4 className={styles.aboutsubtitle2}>Shyam</h4>
              </div>
            </Grid>
            {/* <Grid
              ref={box17}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/aboutus/kirti.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>
              <div>
                <p className={styles.abouttitle}>Technical Lead Opensource</p>
                <h4 className={styles.aboutsubtitle2}>Kriti Praveen</h4>
              </div>
            </Grid> */}
            <Grid
              ref={box19}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/aboutus/mukundannew.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="mukundan_aboutus" />{" "}
                </p>
                <h4 className={styles.aboutsubtitle2}>Mukundan Raghavan</h4>
              </div>
            </Grid>
            <Grid
              ref={box20}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/aboutus/Yashmita.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="yashmitha_aboutus" />{" "}
                </p>
                <h4 className={styles.aboutsubtitle2}>Yashmitha Mutta</h4>
              </div>
            </Grid>
            <Grid
              ref={box21}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/aboutus/gaurinew.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="gauri_aboutus" />
                </p>
                <h4 className={styles.aboutsubtitle2}>Gauri Rananaware</h4>
              </div>
            </Grid>
            <Grid
              ref={box21}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/aboutus/malsawmkimi.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="malsawmkimi_aboutus" />
                </p>
                <h4 className={styles.aboutsubtitle2}>Malsawmkimi Khawlhring</h4>
              </div>
            </Grid>
            <Grid
              ref={box22}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <Link
                  New
                  Message
                  to={{
                    pathname: "/strategicalliances",
                    search: `?lang=${lang}`,
                  }}
                >
                  <img
                    className={styles["img-width"]}
                    id="imghover"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/aboutus/ourstrategic.svg"
                    }
                    alt="About"
                    loading="lazy"
                  />
                </Link>
              </div>

              <div>
                <p className={styles.abouttitle}></p>
                <h4 className={styles.aboutsubtitle2}>
                  <FormattedMessage id="our_strategic_aboutus" />{" "}
                </h4>
              </div>
            </Grid>
            <Grid
              ref={box23}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <Link to="/certifications">
                  <img
                    className={styles["img-width"]}
                    id="imghover"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/aboutus/certification.svg"
                    }
                    alt="About"
                    loading="lazy"
                  />
                </Link>
              </div>

              <div>
                <p className={styles.abouttitle}></p>
                <h4 className={styles.aboutsubtitle2}>
                  <FormattedMessage id="certifications_aboutus" />{" "}
                </h4>
              </div>
            </Grid>
            <Grid
              ref={box24}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <Link
                  New
                  Message
                  to={{
                    pathname: "/clients",
                    search: `?lang=${lang}`,
                  }}
                >
                  <img
                    className={styles["img-width"]}
                    id="imghover"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/aboutus/clients.svg"
                    }
                    alt="About"
                    loading="lazy"
                  />
                </Link>
              </div>

              <div>
                <p className={styles.abouttitle}></p>
                <h4 className={styles.aboutsubtitle2}>
                  <FormattedMessage id="clients_aboutus" />
                </h4>
              </div>
            </Grid>
            <Grid
              ref={box25}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <Link to="/ourteam">
                  <img
                    className={styles["img-width"]}
                    id="imghover"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/aboutus/ourteam.svg"
                    }
                    alt="About"
                    loading="lazy"
                  />
                </Link>
              </div>

              <div>
                <p className={styles.abouttitle}></p>
                <h4 className={styles.aboutsubtitle2}>
                  <FormattedMessage id="our_team_aboutus" />
                </h4>
              </div>
            </Grid>
            <Grid
              ref={box26}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["aboutimages"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <Link to="/locations">
                  <img
                    className={styles["img-width"]}
                    id="imghover"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/aboutus/location.svg"
                    }
                    alt="About"
                    loading="lazy"
                  />
                </Link>
              </div>

              <div>
                <p className={styles.abouttitle}></p>
                <h4 className={styles.aboutsubtitle2}>
                  <FormattedMessage id="locations_aboutus" />
                </h4>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
